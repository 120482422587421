var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.novel.id)?_c('div',{staticClass:"novel-meta"},[_c('h1',{staticClass:"novel-title"},[(_vm.tagText)?_c('van-tag',{staticClass:"tag",attrs:{"round":"","color":_vm.tagText === 'R-18' ? '#fb7299' : '#ff3f3f'}},[_vm._v(_vm._s(_vm.tagText))]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.novel.title)+"\n  ")],1),_vm._v(" "),_c('div',{staticClass:"info-box"},[_c('router-link',{staticClass:"info author",attrs:{"to":{
        name: 'Users',
        params: { id: _vm.novel.user.id },
      }}},[_vm._v(_vm._s(_vm.novel.user.name))]),_vm._v(" "),_c('span',{staticClass:"info words"},[_c('Icon',{staticClass:"icon",attrs:{"name":"novel","scale":"1.1"}}),_vm._v("\n      "+_vm._s(_vm.novel.text_length.toLocaleString("en-US"))+"字\n    ")],1),_vm._v(" "),_c('span',{staticClass:"info like"},[_c('Icon',{staticClass:"icon",attrs:{"name":"like"}}),_vm._v("\n      "+_vm._s(_vm.novel.like.toLocaleString("en-US"))+"\n    ")],1),_vm._v(" "),_c('span',{staticClass:"pixiv"},[_c('a',{attrs:{"href":`https://www.pixiv.net/novel/show.php?id=${_vm.novel.id}`,"target":"_blank","rel":"noreferrer","title":"前往Pixiv查看作品"}},[_c('Icon',{staticClass:"icon",attrs:{"name":"pixiv"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"tag-box"},_vm._l((_vm.novel.tags),function(tag){return _c('router-link',{key:tag.name,staticClass:"tag",attrs:{"to":{
        name: 'Search',
        query: { type: 'novel', keyword: tag.name },
      }}},[_vm._v("#"+_vm._s(tag.name))])}),1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }