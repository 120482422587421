var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank"},[_c('div',{staticClass:"top"},[_c('Nav',{attrs:{"menu":_vm.menu}}),_vm._v(" "),_c('v-date-picker',{attrs:{"attributes":[
        {
          key: 'today',
          highlight: 'yellow',
          dates: _vm.date,
        },
      ],"min-date":_vm.minDate,"max-date":_vm.maxDate,"mode":"single","popover":{
        placement: 'bottom',
        visibility: 'click',
      },"masks":{ title: 'YYYY年 MMM' },"locale":"zh"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.dateNum))])])])],1),_vm._v(" "),(_vm.artList.length >= 3)?_c('Top3',{attrs:{"artList":_vm.artList.slice(0, 3)}}):_vm._e(),_vm._v(" "),(_vm.artList.length > 3)?_c('van-list',{staticClass:"rank-list",attrs:{"finished":_vm.finished,"finished-text":"没有更多了","error":_vm.error,"error-text":"网络异常，点击重新加载"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.getRankList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{ref:"cardBox",staticClass:"card-box__wrapper"},[_c('waterfall',{attrs:{"col":_vm.col,"width":_vm.itemWidth,"gutterWidth":0,"data":_vm.artList.slice(3)}},_vm._l((_vm.artList.slice(3)),function(art){return _c('router-link',{key:art.id,attrs:{"to":{
            name: 'Artwork',
            params: { id: art.id, list: _vm.artList },
          }}},[_c('ImageCard',{attrs:{"mode":"cover","artwork":art}})],1)}),1)],1)]):_vm._e(),_vm._v(" "),_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artList || _vm.artList.length === 0),expression:"!artList || artList.length === 0"}],staticClass:"loading",attrs:{"size":'50px'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }