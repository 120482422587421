var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[(_vm.tags.length > 0)?_c('div',{staticClass:"top"},[_c('div',{staticClass:"tag",on:{"click":function($event){$event.stopPropagation();return _vm.search(_vm.tags[0].name)}}},[_c('img',{attrs:{"src":_vm.tags[0].pic,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"meta"},[_c('div',{staticClass:"content"},[(_vm.tags[0].name)?_c('div',{staticClass:"name",class:{
              s: _vm.tags[0].name.length >= 10,
              m: _vm.tags[0].name.length >= 6,
            }},[_vm._v("\n            #"+_vm._s(_vm.tags[0].name)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.tags[0].tname)?_c('div',{staticClass:"tname",class:{
              s: _vm.tags[0].tname.length >= 10,
              m: _vm.tags[0].tname.length >= 6,
            }},[_vm._v("\n            "+_vm._s(_vm.tags[0].tname)+"\n          ")]):_vm._e()])])])]):_vm._e(),_vm._v(" "),(_vm.tags.length > 3)?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"row"},_vm._l((_vm.tags.slice(1)),function(tag,index){return _c('div',{key:index,staticClass:"tag",on:{"click":function($event){$event.stopPropagation();return _vm.search(tag.name)}}},[_c('img',{attrs:{"src":tag.pic,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"meta"},[_c('div',{staticClass:"content"},[(tag.name)?_c('div',{staticClass:"name",class:[_vm.getLength(tag.name)]},[_vm._v("\n              #"+_vm._s(tag.name)+"\n            ")]):_vm._e(),_vm._v(" "),(tag.tname)?_c('div',{staticClass:"tname",class:[_vm.getLength(tag.tname)]},[_vm._v("\n              "+_vm._s(tag.tname)+"\n            ")]):_vm._e()])])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }