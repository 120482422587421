var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"view",staticClass:"image-view",class:{
    shrink: _vm.isShrink,
    loaded: _vm.artwork.images,
    censored: _vm.isCensored(_vm.artwork),
  },on:{"click":_vm.showFull}},[_vm._l((_vm.artwork.images),function(url,index){return _c('div',{key:index,staticClass:"image-box",style:(index === 0
        ? {
            width: `${_vm.displayWidth}px`,
            height: `${_vm.displayWidth / (_vm.artwork.width / _vm.artwork.height)}px`,
          }
        : null)},[(_vm.lazy)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(url.l),expression:"url.l"}],staticClass:"image",attrs:{"alt":`${_vm.artwork.title} - Page ${index + 1}`},on:{"click":function($event){$event.stopPropagation();_vm.view(index, _vm.isCensored(_vm.artwork))}}}):_c('img',{staticClass:"image",style:({
        width: _vm.displayWidth,
        height:
          (_vm.artwork.width / _vm.displayWidth) *
          _vm.artwork.height *
          (_vm.artwork.width / _vm.artwork.height),
      }),attrs:{"src":url.l,"alt":`${_vm.artwork.title} - Page ${index + 1}`},on:{"click":function($event){$event.stopPropagation();_vm.view(index, _vm.isCensored(_vm.artwork))}}}),_vm._v(" "),(_vm.artwork.type === 'ugoira')?_c('canvas',{ref:"ugoira",refInFor:true,staticClass:"ugoira",attrs:{"width":_vm.artwork.width,"height":_vm.artwork.height,"id":"ugoira"},on:{"click":function($event){return _vm.openDownloadPanel()}}}):_vm._e()])}),_vm._v(" "),(_vm.isShrink)?_c('Icon',{staticClass:"dropdown",attrs:{"name":"dropdown","scale":"4"}}):_vm._e(),_vm._v(" "),(_vm.artwork.type === 'ugoira')?_c('div',{staticClass:"ugoira-controls"},[(_vm.ugoiraPlaying)?_c('div',{staticClass:"btn-pause",on:{"click":function($event){return _vm.drawCanvas('pause')}}},[_c('Icon',{staticClass:"pause",attrs:{"name":"pause","scale":"6"}})],1):_c('div',{staticClass:"btn-play",on:{"click":function($event){return _vm.playUgoira()}}},[_c('Icon',{staticClass:"play",attrs:{"name":"play","scale":"6"}})],1),_vm._v(" "),(_vm.progressShow)?_c('div',{staticClass:"progress-bar",style:({ width: `${_vm.progress * 100}%` })},[_c('div',{staticClass:"background"})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('van-image-preview',{attrs:{"data-scroll":_vm.isScroll,"start-position":_vm.curView,"images":_vm.original},scopedSlots:_vm._u([{key:"cover",fn:function(){return [_c('div',{staticClass:"preview-action__wrapper"},[_c('div',{staticClass:"preview-action",attrs:{"title":"保存图片"},on:{"click":function($event){return _vm.saveImage(_vm.original[_vm.curView])}}},[_c('Icon',{staticClass:"save",attrs:{"name":"save"}})],1)])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }